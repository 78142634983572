<template>
  <section
    class="add-team  fixed w-full h-full z-10"
    @click.stop="clickOnBackground"
  >
    <div
      class="popup-container w-full     rounded"
    >
      <div
        class="popup w-full rounded"
        @click.prevent.stop
      >
        <slot
          class="heartbeat popup-element"
          @click.prevent.stop
        />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'PopupBase',
  props: {
  },
  data () {
    return {
    }
  },
  methods: {
    clickOnBackground () {
      this.$emit('clickOnBackground', false)
      console.log('hi')
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../../assets/style/sass/mixin";
  .add-team {
    background-color: rgba(0,0,0,0.2);
    display: table;
    top: 0;
    left: 0;
    overflow: scroll !important;
  }
  .popup-container {
    display: table-cell;
    vertical-align: middle;
    overflow: scroll !important;
     height: 80% !important;
  }
  .popup {
     width: 55%;
    margin-left: auto;
    margin-right: auto;
    -webkit-animation: scale-up-center 0.4s cubic-bezier(0.400, 0.600, 0.800, 1) both;
      animation: scale-up-center 0.4s cubic-bezier(0.400, 0.600, 0.800, 1) both;
  }
  .popup-element {
    /*animation: bounce 1s ease infinite;*/
    overflow: scroll;
  }
  @keyframes bounce{
    from {transform: translateY(-2px);}
    to   {transform: translateY(-20px);}
  }
  /* ----------------------------------------------
  * Generated by Animista on 2019-12-23 11:29:11
  * Licensed under FreeBSD License.
  * See http://animista.net/license for more info.
  * w: http://animista.net, t: @cssanimista
  * ---------------------------------------------- */

  /**
   * ----------------------------------------
   * animation scale-up-center
   * ----------------------------------------
   */
  @-webkit-keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
      transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  @keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
      transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
</style>
