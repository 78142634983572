<template>
  <div class="page-header">
    <fundind-wallet
      v-if="showFundingDialog"
      @closeFunding="closeFunding"
      @sucsessFunding="sucsessFunding"
    />
    <success-funding-wallet
      v-if="showSucessFunding"
      @closeSuccessFunding="closeSuccessFunding"
    />
    <select-campagn-type
      v-if="wantToChoiceCampaign"
      @emitInApp="emitInApp"
      @emitInSms="emitInSms"
      @emitCloseChoice="emitCloseChoice"
    />
    <h3 class="text-2xl text-bold font-bold capitalize">
      {{ title }}
    </h3>
    <h3 class="text-lg font-light">
      {{ subtitle }}<span
        v-if="isWallet"
        class="walletSold font-bold text-bold"
      >: {{ getWalletBalance }} €</span>
    </h3>
    <div
      v-if="showAction"
      class="flex justify-end  w-full action-button"
    >
      <div
        v-if="selectedOptionForFilter.value === 'personalize' && filterOp === true"
        class=" flex w-1/3 mr-3 mt-1"
      >
        <div class="flex-one w-1/2  h-12">
          <datetime
            v-model="startDate"
            class="border  h-full w-full px-1 py-1  rounded-l-full  my-date-time"
            placeholder="Date de début"
          />
        </div>
        <div class="flex-one w-1/2  h-12">
          <datetime
            v-model="endDate"
            placeholder="Date de fin"
            class="border h-full w-full px-1 py-1  rounded-r-full  my-date-time"
            :min-datetime="minEndDate"
          />
        </div>
      </div>
      <div
        v-if="selectedOptionForFilter.value !== 'personalize' && !showFunding && showFilter && filterOp === true"
        class="flex-one  w-1/5 p-1"
      >
        <div
          v-click-outside="hideFilterOption"
          class="p-3 rounded-full text-center cursor-pointer border"
          @click="showFilterOption = !showFilterOption"
        >
          <svg-icon
            name="ic-select-calendar"
            class="w-4  -ml-6 h-4 cursor-pointer"
            original
          />
          {{ selectedOptionForFilter.label }}
          <svg-icon
            name="ic_select"
            class="w-4 h-4 mt-1 cursor-pointer float-right"
            original
          />
        </div>
      </div>
      <div
        v-if="!showFunding"
        class="flex-one  w-1/5 p-1"
      >
        <div
          class="p-3 cursor-pointer font-bold rounded-full border-none action-button-real text-white text-center"
          @click="startNewCanpaign"
        >
          {{ $t('newCampaigns') }}
        </div>
      </div>
      <div
        v-if="showFunding"
        class="flex-one  w-1/6 p-1"
      >
        <div
          class="p-3 cursor-pointer font-bold rounded-full border-none action-button-real text-white text-center"
          @click="showFundingDialog = true"
        >
          {{ $t('funding') }}
        </div>
      </div>
    </div>
    <hr class="divider">
    <div
      v-if="showFilterOption"
      class="absolute bg-white rounded shadow select-list"
    >
      <h3
        v-for="(item, key) in filterOptions"
        :key="item + key"
        class="text-xl font-light flex-one p-2 cursor-pointer"
        :class="{'is-selected': item.value === selectedOptionForFilter.value}"
        @click="selectOption(item)"
      >
        {{ item.label }}
      </h3>
    </div>
    <!--<b @click="payWithDunya" class="cursor-pointer">PaywithPayDunya</b>-->
  </div>
</template>

<script>
import FundindWallet from './fundind-wallet'
import SuccessFundingWallet from './success-funding-wallet'
import firebase from 'firebase'
import { firebaseDb } from '../../../main'
import { firebaseRef } from '../../../rema-function/firebaseRef'
import ClickOutside from 'vue-click-outside'
import { Datetime } from 'vue-datetime'
import SelectCampagnType from './select-campaign-type'

export default {
  name: 'PageHeader',
  components: {
    SelectCampagnType,
    SuccessFundingWallet,
    FundindWallet,
    datetime: Datetime
  },
  directives: {
    ClickOutside
  },
  props: {
    filterOp: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: 'Title'
    },
    subtitle: {
      type: String,
      default: 'Subtitle'
    },
    showAction: {
      type: Boolean,
      default: true
    },
    showFunding: {
      type: Boolean,
      default: false
    },
    isWallet: {
      type: Boolean,
      default: false
    },
    showFilter: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      showFundingDialog: false,
      showSucessFunding: false,
      wallet: {},
      showFilterOption: false,
      selectedOptionForFilter: {
        label: this.$t('lastThirty'),
        value: 'thirty'
      },
      filterOptions: [
        {
          label: this.$t('lastThirty'),
          value: 'thirty'
        },
        {
          label: this.$t('lastThree'),
          value: 'three'
        },
        {
          label: this.$t('lastSix'),
          value: 'six'
        },
        {
          label: this.$t('lastTwelve'),
          value: 'twelve'
        },
        {
          label: this.$t('personalise'),
          value: 'personalize'
        }
      ],
      startDate: '',
      endDate: '',
      wantToChoiceCampaign: false
    }
  },
  computed: {
    minEndDate () {
      if (this.startDate !== '') {
        let ff = new Date(new Date(this.startDate).getTime() + 24 * 60 * 60 * 1000)
        // alert(ff.toISOString())
        return ff.toISOString()
      } else {
        return new Date().toISOString()
      }
    },
    getWalletBalance: function () {
      if (typeof this.wallet !== 'undefined' && typeof this.wallet.balance !== 'undefined') {
        console.log(this.wallet.balance)
        return Number.parseFloat(this.wallet.balance.toString()).toFixed(2)
      } else {
        return 0
      }
    }
  },
  watch: {
    'startDate': function (newValue) {
      this.$emit('startDate', newValue)
    },
    'endDate': function (newValue) {
      this.$emit('endDate', newValue)
    }
  },
  methods: {
    closeFunding (answer) {
      this.showFundingDialog = answer
    },
    emitCloseChoice () {
      this.wantToChoiceCampaign = false
    },
    closeSuccessFunding (answer) {
      this.showSucessFunding = answer
    },
    sucsessFunding (answer) {
      this.showSucessFunding = answer
    },
    startNewCanpaign () {
      this.wantToChoiceCampaign = true
      // this.$router.replace('campaigns/campaign-sms')
    },
    emitInSms () {
      this.wantToChoiceCampaign = false
      this.$router.replace('campaign-sms/new-sms-campaign')
    },
    emitInApp () {
      this.wantToChoiceCampaign = false
      this.$router.replace('campaigns/new-campaign')
    },
    selectOption (option) {
      this.selectedOptionForFilter = option
      this.$emit('filterOptionSelected', this.selectedOptionForFilter)
      this.showFilterOption = false
    },
    hideFilterOption () {
      this.showFilterOption = false
    },
    // TODO HERE IF WANT TO IMPLEMENT PAYDUNYA
    payWithDunya () {
      // alert('his')
      let payDunya = require('paydunya')
      let setup = new payDunya.Setup({
        masterKey: 'mlbbrcjJ-RgO0-RSR3-1sXa-xCH1RKKutNkf',
        privateKey: 'live_private_thUsHtOyltSfQ0C3n4undcuAgXE',
        publicKey: 'live_public_FVZ6UDwkIEI5nuF5ERsUl4r8bTA',
        token: 'Qwj77zkpY9uz3LCOV1sC'
        // mode: 'live' // optional. use in sandbox mode.
      })
      let store = new payDunya.Store({
        name: 'REMA Medical Technologies' // Seul le nom est requis
      })
      let invoice = new payDunya.CheckoutInvoice(setup, store)
      invoice.addItem('Chaussures Croco', 1, 10000, 30000, 'Chaussures faites en peau de crocrodile authentique qui chasse la pauvreté')
      invoice.totalAmount = 10000
      invoice.addChannel('card')
      invoice.create('alikhaliq66@gmail.com').then((res) => {
        // alert('ici')
        console.log(invoice.status)
        console.log(invoice.token) // Token de facture
        console.log(invoice.responseText)
        console.log(invoice.url)
      })
        .catch((err) => {
          // alert('error')
          console.log(err)
        })
    }
  },
  firebase () {
    return {
      wallet: firebaseDb.ref(firebaseRef.wallets).child(firebase.auth().currentUser.uid)
    }
  }
}

</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";
  .action-button {
    margin-top: -3rem;
  }
  .action-button-real {
    background-color: $Green-Rema;
    font-weight: 400;
    font-size: 1.1rem;
  }
  .divider {
    height: .5px;
    background-color: rgba(206,206,206, .2);
    opacity: .4%;
  }
  .walletSold {
    color: $Green-Rema;
  }
  .select-list {
    display: block;
    right: 18%;
    top: 20%;
    width: 13%;
  }
  .is-selected {
    color: $red;
  }
  .my-date-time /deep/ {
    input {
      background-color: transparent;
      padding-top: .7rem;
      margin-left: -1.5rem;
      text-align: center;
      cursor: pointer;
    }
  }
</style>
