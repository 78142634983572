<template>
  <div class="select-campaign-type"
     @click="emitCloseChoice">
    <div class="sub-select" @click.prevent.stop>
      <div class="father flex justify-center bg-red">
        <div class="real-container bg-white w-full rounded p-6">
          <h3 class="text-2xl font-medium"> {{ $t('newCampaigns') }} </h3>
          <div class="mt-3 choice border-t pt-2 cursor-pointer flex" @click="emitInAPP">
            <div class="flex-one w-1/4">
              <svg-icon
                name="ic-campaign-in-app"
                class="w-16 h-16"
                original
                />
            </div>
            <div class="flex-one w-full">
                <h3 class="mt-1"> {{ $t('inAppTitle') }} </h3>
                <p class="text-sm pr-2"> {{ $t('inAppSubtitle') }} </p>
            </div>
            <div class="flex-one mt-6 gg">
              <svg-icon
                name="ic-next"
                color="#CECECE"
                class="w-4 h-4"
              />
          </div>
          </div>
          <div class="mt-3 choice border-t pt-2 cursor-pointer flex" @click="emitInSms">
            <div class="flex-one w-1/4">
              <svg-icon
                name="ic-campaign-sms"
                class="w-16 h-16"
                original
              />
            </div>
            <div class="flex-one w-full">
              <h3 class="mt-1"> {{ $t('inSmsTitle') }} </h3>
              <p class="text-sm pr-2"> {{ $t('inSmsSubtitle') }} </p>
            </div>
            <div class="flex-one mt-6 gg">
              <svg-icon
                name="ic-next"
                class="w-4 h-4"
                color="#CECECE"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectCampagnType',
  components: { },
  methods: {
    emitCloseChoice () {
      this.$emit('emitCloseChoice', false)
    },
    emitInAPP () {
      this.$emit('emitInApp', false)
    },
    emitInSms () {
      this.$emit('emitInSms', false)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";
 .gg {
   width: 5%;
  }
.select-campaign-type {
  display: flex;
  justify-content: center;
  position: fixed;
  height: 100vh;
  min-height: 100vh;
  align-items: center;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.39);
  top: 0;
  left: 0;
  color: rgba(0, 0, 0, 0.3);
  z-index: 50;
  overflow-y: scroll;
  text-align: left;
}
.sub-select {
  position: relative;
  display: flex;
  color: black;
  flex-direction: column;
  // height: 100%;
  margin-bottom: 50px;
  // min-height: 1000px;
  //  background-color: red;
  width: 30%;
  // box-shadow: 0 2px 10px rgba(0,0,0,0.2);
}
</style>
