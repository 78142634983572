<template>
  <div class="success-funding-wallet"
       @click="emitClose">
    <popup-base @clickOnBackground="emitClose">
      <div class=" p-2 rounded">
        <div class="bg-white p-2 text-center success-funding-wallet rounded">
          <svg-icon
            name="ic-sucess"
            class="w-16 h-16"
            original
          />
          <h3 class="mt-4 font-normal text-xl  "> {{ $t('successFunding') }}</h3>
          <div class="success-button mt-4">
            <button @click="emitClose" class="code-section uppercase px-6 py-4 text-center w-full text-xl code-input font-bold text-white rounded bg-green px-6">
              Ok
            </button>
          </div>
        </div>
      </div>
    </popup-base>
  </div>
</template>

<script>
import PopupBase from './popup-base'
export default {
  name: 'SuccessFundingWallet',
  components: { PopupBase },
  data () {
    return {
    }
  },
  methods: {
    emitClose () {
      this.$emit('closeSuccessFunding', false)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";
.success-funding-wallet {
  width: 40%;
  margin: auto;
}
</style>
