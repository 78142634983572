<template>
  <div class="histories">
    <page-header
      :title="title"
      :subtitle="subtitle"
      :show-action="false"
    />
    <div class="page_content pt-6 pl-10">
      <h3 class="text-xl font-light">
        <svg-icon
        name="ic-contact-email"
        class="w-6 pt-1 h-4"
        original>
        </svg-icon>
          contact@remaapp.com
      </h3><br>
      <h3 class="text-xl font-light">
        <svg-icon
          name="ic-contact-phone"
          class="w-6 pt-1 h-4"
          original>
        </svg-icon> +229 96 20 06 13<br>
        <svg-icon
          name="ic-contact-email"
          class=" invisible w-6 pt-1 h-4"
          original>
        </svg-icon> +229 96 50 81 30<br>
        <svg-icon
          name="ic-contact-email"
          class="invisible w-6 pt-1 h-4"
          original>
        </svg-icon> +1 819 213 96 22
      </h3><br>
      <h3 class="text-xl font-light">
        <svg-icon
          name="ic-contact-localisation"
          class="w-6 h-4"
          original>
        </svg-icon> C/982 Hinde-sud,<br>
        <svg-icon
          name="ic-contact-localisation"
          class="w-6 h-4 invisible"
          original>
        </svg-icon> 09 BP 0573, Cotonou, Bénin
      </h3><br>
      <h3 class="text-xl font-light">
        <svg-icon
          name="ic-contact-localisation"
          class="w-6 h-4 invisible"
          original>
        </svg-icon> 12 Rue Notre-Dame sud, Ville-Marie,<br>
        <svg-icon
          name="ic-contact-localisation"
          class="w-6 h-4 invisible"
          original>
        </svg-icon> Qc, Canada, J9V 1X5
      </h3><br>
      <hr class="divider"><br>
      <div class="flex justify-between  w-1/2">
        <div class="flex-one">
          <a class="link-cgu text-lg" :href="cguLink" target="_blank">Conditions Génrales d’Utilisation</a>
        </div>
        <div class="flex-one">
          <a class="link-cgu text-lg" :href="pdcLink" target="_blank">Politique de Confidentialité</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from '../../components/helper/rema-components/page-header'
import { remaDefaultData } from '../../rema-function/remaDefaultData'

export default {
  name: 'HomeCollabos',
  components: { PageHeader },
  props: {
    msg: String
  },
  data () {
    return {
      title: this.$t('contactUs'),
      subtitle: this.$t('contactSubtitle'),
      cguLink: remaDefaultData.cguLink,
      pdcLink: remaDefaultData.pdcLink
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/style/sass/variables";
.link-cgu {
//  font-size: 1rem;
  color: $Green-Rema;
  cursor: pointer;
}
  .divider {
    height: 1px;
    background-color: rgba(206,206,206, .2);
    opacity: .4%;
  }
</style>
